.calendar-container {
  font-family: Arial, sans-serif;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.breadcrumb {
  color: #666;
  font-size: 14px;
  margin-bottom: 20px;
}

.home-icon {
  margin-right: 5px;
}

.title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 40px;
}

.connect-section {
  text-align: center;
}

.icon-container {
  margin-bottom: 20px;
}

.connect-icon {
  width: 80px;
  height: 80px;
  color: #666;
}

.connect-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
}

.connect-description {
  color: #666;
  font-size: 16px;
  max-width: 500px;
  margin: 0 auto 30px;
}

.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
}

.connect-button {
  background-color: #f0f0f0;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.connect-button:hover {
  background-color: #e0e0e0;
}

.connect-button.gmail {
  background-color: #4285f4;
  color: white;
}

.connect-button.gmail:hover {
  background-color: #3367d6;
}

.or-text {
  margin: 0 15px;
  color: #666;
}
