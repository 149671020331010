.add-event-container {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  width: 300px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

h2 {
  margin-top: 0;
  margin-bottom: 20px;
}

.input-field {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  font-size: 14px;
}

.select-wrapper {
  position: relative;
}

.select-wrapper::after {
  content: '▼';
  font-size: 12px;
  top: 50%;
  right: 10px;
  position: absolute;
  transform: translateY(-50%);
}

.time-input {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.clock-icon {
  margin-right: 10px;
}

.time {
  width: 45%;
}

.textarea {
  height: 80px;
  resize: vertical;
}

.toggle-container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
  margin-right: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #6c5ce7;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.automation-options {
  background-color: #f8f8f8;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
}

.automation-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.small-select {
  width: 48%;
  padding: 5px;
  font-size: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
}

.user-select {
  width: 100%;
  padding: 5px;
  font-size: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
}

.create-button {
  width: 100%;
  padding: 10px;
  background-color: #2d3436;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.create-button:hover {
  background-color: #1e272e;
}
